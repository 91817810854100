import { Typography } from "@mui/material";
import React from "react";
import GithubIcon from "../Images/logo_github_icon.png";

export function GithubLink() {
  return (
    <div className="icon-area">
      <a target="_blank" href="https://github.com/alifensome" rel="noreferrer">
        <img className="icon" src={GithubIcon}></img>
        <Typography display="inline-flex" paddingBottom="1em">
          Github Ali Fensome
        </Typography>
      </a>
    </div>
  );
}
