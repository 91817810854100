import React from "react";
import { Avatar, Typography } from "@mui/material";
import ProfilePic from "../Images/profile_pic_homebrew.jpeg";
import { GithubLink } from "./GithubLink";

export function ProfilePictureFlexSection() {
  return (
    <div className="flex-container">
      <div className="flex-item-right">
        <div className="img-wrap">
          <Avatar
            alt="Ali Fensome"
            src={ProfilePic}
            sx={{ width: "12em", height: "auto" }}
          />
        </div>
      </div>
      <div className="flex-item-left">
        <Typography>
          Hi I'm Ali, a Software Consultant, Full Stack Developer and Data
          Engineer. I specialise in Javascript, NodeJs, Typescript, React and
          IaC. I love devOps, TDD and getting projects done!
          <br />
          <br /> When I'm not writing code, I like to{" "}
          <a
            className="inline-link"
            target="_blank"
            href="https://www.instagram.com/forbidden_fruits_brewery/"
          >
            Homebrew
          </a>
          , walk my dog Logan and am on the comity for my local running club{" "}
          <a
            className="inline-link"
            target="_blank"
            href="https://levenshulme-runners.co.uk/"
          >
            Levenshulme Runners
          </a>
          .
          <br />
          <br /> Drop me a message or checkout my github repo for more
          information.
        </Typography>
        <GithubLink />
      </div>
    </div>
  );
}
